<template>
  <v-autocomplete
    v-bind="$attrs"
    :loading="!!loading"
    :search-input.sync="search"
    :items="items"
    solo
    item-text="label"
    item-value="name"
    clearable
    hide-no-data
    return-object
    dense
    placeholder="Search or add a reason"
    @input="handleInput"
  />
</template>
<script>
import { debounce, get } from "lodash";
import gql from "graphql-tag";
import { hasSnackbarAccess } from "@/mixins/ui";
import { BUY_REASON_AUTOCOMPLETE } from "../graphql";

export default {
  mixins: [hasSnackbarAccess],
  inheritAttrs: false,
  props: {
    // `pass` || `take`
    type: {
      type: String,
      required: true,
    },
  },
  apollo: {
    // To manually control this query, it's defined as a fn,
    // which means it's not reactive to changes to variables
    reasons() {
      return {
        loadingKey: "loading",
        query: BUY_REASON_AUTOCOMPLETE,
        variables: {},
        skip() {
          return this.skipSearch;
        },
        result() {
          this.typing = false;
        },
        error() {
          this.typing = false;
        },
        update(response) {
          return response.searchAllBuyReasons.reasons.map((reason) => ({
            label: reason,
            name: reason,
          }));
        },
        fetchPolicy: "cache-and-network",
      };
    },
  },
  data: () => ({
    // minSearchLength:
    //   This controls whether search is fired.
    //   Set this to the minimum required length
    //   for any search to automatically fire
    //   once typing stops
    minSearchLength: 3,
    search: undefined,
    // Manually control search
    skipSearch: true,
    reasons: [],
    typing: false,
    loading: 0,
  }),
  computed: {
    items() {
      const search = (this.search && this.search.trim()) || undefined;
      // If there is no search value, return an empty array
      if (!search) return [];

      // If `items` is empty and the user isn't typing, return a `create new` option
      const items = get(this, "reasons", []);
      if (!items.length && !this.typing && !this.loading) {
        return [
          {
            name: search,
            label: `Create new ${this.type} reason: ${search}`,
          },
        ];
      }

      // Return the results
      return items;
    },
  },
  watch: {
    search(v) {
      v = (v && v.trim()) || undefined;
      // Guard
      if (!v) return;
      // Set typing to true to prevent the `Create new reason` prompt to show
      if (v) this.typing = true;
      // Only fire a network request if the query meets the minimum length
      if (v && v.length < this.minSearchLength) {
        this.skipSearch = true;
        return;
      }

      return this.resetResults() && this.debounceQuery(v);
    },
  },
  methods: {
    // Debounce `queryReasons`
    debounceQuery: debounce(function (searchValue) {
      return this.queryReasons(searchValue);
    }, 600),
    // Query reasons of the given `type`
    queryReasons(searchValue) {
      this.skipSearch = false;

      this.$apollo.queries.reasons.refetch(
        this.queryReasonsVariables(searchValue)
      );
    },
    // Generate variables for searching reasons
    queryReasonsVariables(searchValue) {
      return {
        input: {
          storeId: this.$route.params.storeId,
          q: searchValue,
          type: this.type,
        },
      };
    },
    // Clears the search results
    resetResults() {
      this.reasons = [];
      return true;
    },
    // Resets the internal `data` to the default state
    resetData() {
      this.search = undefined;
      this.skipSearch = true;
      this.reasons = [];
      this.typing = false;
    },
    // If `reason` is *new*, create it.
    // Then, reset the field and emit `input`
    async handleInput(reason) {
      try {
        // Clear the field and emit the reason
        this.resetData();
        this.$emit("input", reason);
      } catch (error) {
        this.showSnackbar({
          text: `An error occurred while creating the ${this.type} reason: ${error}`,
        });
      }
    },
  },
};
</script>
